import {Vue} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import JsonTool from "@/services/tool/JsonTool"
import ProductCategory from "@/models/ProductCategory"
import SnackbarModule from "@/store/SnackbarModule"

export default class ProductCategoryService {

	static async getProductCategoriesTest(component: Vue, productCategories: ProductCategory[]) {
		(<any>component).loading = true
		try {

			const response: any = await component.axios.get(ConstantTool.BASE_URL + "/public/product-categories")
			let list = JsonTool.jsonConvert.deserializeArray(response.data, ProductCategory)

			// Create root for top-level node(s)
			const root: ProductCategory[] = [];
			  
			list.forEach(productCategory => {

				if (productCategory.parent == null) return root.push(productCategory)
				
				// Insert node as child of parent in flat array
				const parentIndex = list.findIndex(el => el.id === productCategory.parent?.id)
				if (!list[parentIndex].children || list[parentIndex].children?.length == 0) {
					return list[parentIndex].children = [productCategory];
				}
				
				list[parentIndex].children?.push(productCategory);
			})

			  root.forEach(pc => productCategories.push(pc))
			
		} catch (error) {
			
		}
	}

	static async getProductCategories(component: Vue, productCategories: ProductCategory[], filterParent: boolean = false) {
		(<any>component).loading = true
		try {
			const response: any = await component.axios.get(ConstantTool.BASE_URL + "/public/product-categories")
			let list = JsonTool.jsonConvert.deserializeArray(response.data, ProductCategory)
			productCategories.splice(0, productCategories.length)
			if (filterParent) {
				list.forEach(pc => {
					if (pc.parent == null) productCategories.push(pc)
				})
				console.log(productCategories)
			} else {
				list.forEach(pc => productCategories.push(pc))
			}
		} catch (err) {
			// @ts-ignore
			component.loading = false
			getModule(SnackbarModule).makeToast("No se han podido obtener las familias de productos")
		} finally {
			(<any>component).loading = false
		}
	}

	static getProductCategory(component: Vue, productCategoryId: number) {
		// @ts-ignore
		component.loading = true
		component.axios.get(ConstantTool.BASE_URL + "/public/product-categories/" + productCategoryId)
			.then((response: any) => {
				// @ts-ignore
				component.productCategory = JsonTool.jsonConvert.deserializeObject(response.data, ProductCategory)
			})
			.catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener las familia de productos"))
			// @ts-ignore
			.finally(() => component.loading = false)
	}
}
