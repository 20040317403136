








































import CarouselComponent from "@/components/CarouselComponent.vue"
import ProductCategory from "@/models/ProductCategory"
import ProductCategoryService from "@/services/ProductCategoryService"
import ConstantTool from "@/services/tool/ConstantTool"
import LangModule from "@/store/LangModule"
import {Component, Vue, Watch} from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"

@Component
export default class QueryComponent extends Vue {
	lang: any = getModule(LangModule).lang
}
